import React, { Component, useState } from 'react';
import {
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import { withTranslation, Translation, } from 'react-i18next';
import i18n from "../../i18n";

import { View, Text, Image, ActivityIndicator} from "react-native-web";

import HamburgerMenu from 'react-hamburger-menu';

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function HeaderMobile(props) {
  const currentYear = new Date().getFullYear();
  const [open, setOpen] = useState(false);
   const navigate = useNavigate();

   const location = useLocation();

   let pathname = location.pathname;


   const handleClick = () => {
       let bool = !open;
       setOpen(bool);

       props.handleMenuClick(bool);
   }

   console.log();

    return (

      <div className="main_header" style={{position: (props.hdrIsRel !== undefined && props.hdrIsRel === true) ? 'relative':'fixed', backgroundColor: '#fff', borderBottom: 'none', width: '100%'}}>
        <div className="main_header_inside">
          <div className="main_header_inside_left" style={{marginLeft: '-10px', width: '116px'}}>
              <a href="/"><img src={'/img/digiagreem_logo_med.png'} alt="digiAgreem Logo" style={{width: '133px', height: '40px'}}/></a>
          </div>

          <div className="main_header_inside_left_2" style={{display: 'none', width: '70%'}}>
               <a href="/dplay" className="nonactiveHeaderLink">DPlay</a>
              <a href="/about/dshop" className="nonactiveHeaderLink">DShop</a>
              <a href="/about/dchat" className="nonactiveHeaderLink">DChat</a>
              <a href="https://www.yinitj.com/" target="_blank" className="nonactiveHeaderLink">Youth&nbsp;Initiative&nbsp;Jeunesse</a>
          </div>

          {(props.hdrIsRel === undefined || (props.hdrIsRel !== undefined && props.hdrIsRel !== true)) &&
            <div className="main_header_inside_right_1" style={{marginTop: '17px', marginRight: '10px'}}>
                   <HamburgerMenu
                        isOpen={open}
                        menuClicked={handleClick}
                        width={35}
                        height={18}
                        strokeWidth={1}
                        rotate={0}
                        color='#000'
                        borderRadius={0}
                        animationDuration={0.5}
                       />
            </div>
          }

          <div className="main_header_inside_right_1" style={{display: 'none', lineHeight: '40px', marginRight: '10px'}}>
              <a href="/contact" style={{fontSize: '16px', color: '#3a81dd'}}>Contact</a>
          </div>

          <div style={{clear: 'both'}}/>

        </div>
      </div>

    )

}
